<script>
import { ref } from 'vue';
 import { Thumbs } from 'swiper';

import Layout from "../../../layouts/main.vue";
import appConfig from "../../../../app.config";
import PageHeader from "@/components/page-header";

import SwiperCore, { Pagination, Navigation } from "swiper";
//import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";
import serverConfig   from '@/helpers/config';
import axios from 'axios';
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import moment from 'moment';
//import VuePictureSwipe from 'vue3-picture-swipe';
SwiperCore.use([Pagination, Navigation]);

export default {
  page: {
    title: "Dettaglio prodotto",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
   setup() {
      const thumbsSwiper = ref(null);
      const setThumbsSwiper = (swiper) => {
        thumbsSwiper.value = swiper;
      };
      let tk = 'Bearer '+localStorage.getItem('tk')
      return {
        Thumbs,
        thumbsSwiper,
        setThumbsSwiper,
        tk,
      };
    },
  data() {
    return {
      title: "Dettaglio prodotto",
      UrlServer: serverConfig.EP,
      datastore: this.$store.state,
      userData: this.$store.state.auth.currentUser,
      item:{},
      items: [
        {
          text: "Catalogo",
          href: "/ecommerce/products",
        },
        {
          text: "Dettaglio prodotto",
          active: true,
        },
      ],
      galleryItems: [
      ],
      fileList:[],
      productDetailsWidgets: [
      ],
      salesRouteList:[],
    };
  },
  mounted(){
    this.getItem() 
    this.getSRLUser()
   
  },
  methods:{
    ...mapActions('ecommercesettings', ['getBrands','addToCart', 'getCartByOrd']),
    ...mapActions('auth', ['getSalesRoute', ]),
    ...mapActions('layout', ['changeLayoutType', ]),
    axiosInterceptor(){
      axios.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
            if (error.response && error.response.status === 401) {
              Swal.fire({
                title:"Accesso non autorizzato",
                text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                this.$router.push('/login')
                if(localStorage.getItem('tk')){
                  localStorage.removeItem('tk')
                }
                return Promise.reject('Unauthorized access');
            } else if (error.response && error.response.status  === 404) {
                Swal.fire({
                  title:"Nessun risultato trovato",
                  text:"Mi spiace, nessun risultato trovato",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('The server cannot find the requested resource');
            }  else if (error.response && error.response.status  === 412) {
              Swal.fire({
                title:"Credenziali mancanti",
                text:"Inserisci delle credenziali valide, per favore.",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
              return Promise.reject('The server cannot find the requested resource');
          } else if (error.response && error.response.status  === 423) {
            Swal.fire({
                      title:"Risorsa non accessibile",
                      text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:3000,
                    })
                this.$router.push('/')           
                return Promise.reject('The server cannot find the requested resource');
            }
            else if (error.response && error.response.status  === 500) {
              Swal.fire({
                title:"Errore Server",
                text:"Mi spiace, ma riscontriamo degli errori sul server.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                return Promise.reject('Internal Server Error');
            }
            else if (error.response && error.response.status  === 502) {
                Swal.fire({
                  title:"Errore Server",
                  text:"Mi spiace, ma riscontriamo degli errori sul server.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('Bad Gateway');
            }
            else if (error.response && error.response.status  === 503) {
              Swal.fire({
                title:"Servizio non disponibile",
                text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                return Promise.reject('Service Unavailable');
            } else {
              console.log(error.response.status )
              Swal.fire({
                title:"Errore imprevisto",
                text:"Ops! si è verificato un problema...riprova, per favore.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
              
            }

        }
      );
      
    },
    roundTo(value, decimalpositions)
    {
      var i = value * Math.pow(10,decimalpositions);
      i = Math.round(i);
      return i / Math.pow(10,decimalpositions);
    },
    formatPrice(value) {
      let val = (value/1).toFixed(2).replace('.',',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    repl(par,val){
      if (par){
        if(par.includes(val)){
          return par.replaceAll(val,' ')
        }
      }
    },
    getSRLUser(){
      if (this.userData){
        this.getSalesRouteList(this.userData.id)
      }
    },
    getSalesRouteList(id){
      if ( localStorage.getItem('tk')) {
        this.axiosInterceptor()
        axios.get(`${this.UrlServer}sales-route/srlcompany/${id}`,  
          {headers: { authorization:`Bearer ${ localStorage.getItem('tk') }`}} 
        ).then((response)=>{
          if (response.data){
            this.salesRouteList = response.data
          }
        })   
      }
    }, 
    salesRouteFilter(prezzoListino, CodMarca,codGruppo){
      let sl = this.salesRouteList.filter(function(el){
        if (el.salesRouteList.length>0){
          return el.salesRouteList[0].CodMarca == CodMarca
        }
      })
      if (sl){
        let label = ''
        let tmp = {}
        let prezzoCalcolato = 0
        tmp["codCanale"] = {label:'Codice Canale', value:sl[0].salesRouteList[0]['SLCode']}
        tmp["tipoCanale"] = {label:'Tipo Canale', value:sl[0].salesRouteList[0]['SLDescription']}
        tmp["scontoBase"] = {label:'Sconto Base', value:sl[0].salesRouteList[0]['RouteDiscountBase']}
        tmp["scontoGruppoProdotto"] = {label:'Sconto sul Prodotto', value:sl[0].salesRouteList[0][codGruppo]}

				if (sl[0].salesRouteList[0]['RouteDiscountBase']>0) {
					// Calcola prezzo applicando lo sconto base
					prezzoCalcolato = prezzoListino - ((prezzoListino*sl[0].salesRouteList[0]['RouteDiscountBase'])/100)
          label +='Sconto Base: '+sl[0].salesRouteList[0]['RouteDiscountBase']+'%'
				}
	
				if (sl[0].salesRouteList[0]['RouteDiscount_2']>0) {
					// Calcola prezzo applicando lo sconto 2
					prezzoCalcolato = prezzoCalcolato - ((prezzoCalcolato*sl[0].salesRouteList[0]['RouteDiscount_2'])/100)
          label +='<br>Sconto 2: '+sl[0].salesRouteList[0]['RouteDiscount_2']+'%'
				}
				if (sl[0].salesRouteList[0]['RouteDiscount_3']>0) {
          // Calcola prezzo applicando lo sconto 3
          prezzoCalcolato = prezzoCalcolato - ((prezzoCalcolato*sl[0].salesRouteList[0]['RouteDiscount_3'])/100)
          label +='<br>Sconto 3: '+sl[0].salesRouteList[0]['RouteDiscount_3']+'%'
				}
	
				// Sconto legato al Gruppo
				let gruppoSconto = sl[0].salesRouteList[0][codGruppo]
				if (gruppoSconto > 0 ) {
          prezzoCalcolato = prezzoCalcolato - ((prezzoCalcolato*gruppoSconto)/100)
          label +='<br>Sconto per Gruppo prodotto: '+gruppoSconto+'%'
				}
				// Sconto su gruppo "Agent discount"
				if (sl[0].salesRouteList[0]['AD_MULTI']>0) {
          // Calcola prezzo applicando lo sconto AD_MULTI
          prezzoCalcolato = prezzoCalcolato - ((prezzoCalcolato*sl[0].salesRouteList[0]['AD_MULTI'])/100)
          label +='<br>Sconto Agent Multi: '+sl[0].salesRouteList[0]['AD_MULTI']+'%'
        }
        if (sl[0].salesRouteList[0]['AD_SPLIT']>0) {
          // Calcola prezzo applicando lo sconto AD_SPLIT
          prezzoCalcolato = prezzoCalcolato - ((prezzoCalcolato*sl[0].salesRouteList[0]['AD_SPLIT'])/100)
          label +='<br>Sconto Agent Split: '+sl[0].salesRouteList[0]['AD_SPLIT']+'%'
        }
        if (sl[0].salesRouteList[0]['AD_VRV_MINI_VRV']>0) {
          // Calcola prezzo applicando lo sconto AD_VRV_MINI_VRV
          prezzoCalcolato = prezzoCalcolato - ((prezzoCalcolato*sl[0].salesRouteList[0]['AD_VRV_MINI_VRV'])/100)
          label +='<br>Sconto Agent VRV: '+sl[0].salesRouteList[0]['AD_VRV_MINI_VRV']+'%'
        }
        if (sl[0].salesRouteList[0]['AD_MINI_CHILLER']>0) {
          // Calcola prezzo applicando lo sconto AD_VRV_MINI_VRV
          prezzoCalcolato = prezzoCalcolato - ((prezzoCalcolato*sl[0].salesRouteList[0]['AD_MINI_CHILLER'])/100)
          label +='<br>Sconto Agent MINI CHILLER: '+sl[0].salesRouteList[0]['AD_MINI_CHILLER']+'%'
        }
        label += `<br><span class="badge badge-outline-secondary badge-lg">Prezzo: <strong>${this.formatPrice(prezzoCalcolato).toString()} &euro;</strong></span>`
				
        return label
      } else {
        return 'N.D.'
      }

    },
    getItem() {
      this.data = {}
      this.axiosInterceptor()
      axios.get(`${this.UrlServer}products/${this.$route.params.id}`, {headers: { authorization:this.tk}}).then((response) => {
          if (response.data && response.data.length > 0) {
            this.item = response.data[0]
            if (this.item.file_list){
              this.fileList = this.item.file_list
              for (let item of this.fileList) {
                if (item.mimetype=='image/jpeg' || item.mimetype=='image/png' || item.mimetype=='image/jpg') {
                  this.galleryItems.push({src: "",thumbnail:"",w: 1200,h: 900})
                }
              }  
            }
          }                  
      });
    },
    getFilePath(filePath,index) {
      this.axiosInterceptor()
      axios.get(`${this.UrlServer}storage/f/db/to/gcloud/get-signed-url`, 
        { 
          params: { 
            filepath: filePath,
          }, 
          headers: { 'authorization':this.tk}
        }
       ).then(response=>{

          if (response.data){
            this.fileList[index].resp = response.data
            this.galleryItems[index].src = response.data
            this.galleryItems[index].thumbnail = response.data
            this.galleryItems[index].w = 1200
            this.galleryItems[index].h = 900
          } else {
            this.fileList[index].resp = false
          }
        
        })
    },
    replName(val,el){
      if (val.includes(el)){
        return val.replaceAll(el,' ')
      } else {
        return val
      }
    },
    formatKb(val){
      if (val){
        return this.roundTo(val / 1024, 1)
      } else {
        return ''
      }
    },
    fileTypeCheck(file){
      if (file){
        if (file.split('/')[0] == 'image'){
          return 'image'
        } else {
          return file
        }
      
      }
    },
    addTC(gcode,codMarca, qty){
      moment.locale('it')
      let momentCode = moment().unix()
      let orx = false
     
      console.log(this.salesRouteList)

      if ( localStorage.getItem("orx") ) {
        orx = localStorage.getItem("orx")
      } else {
        localStorage.setItem("orx", orx)
      }
      let sr = false
      for (let item of this.salesRouteList){
        if (item.CodMarca == codMarca){
          sr = item.Codice
        }
      }

      let data = { 
          productCode: gcode,
          userId: this.userData.uid,
          cuuid:momentCode,
          orx:orx,
          salesRoute:sr,
          qta: qty,
      }
      this.addToCart(data)
    },

  },
  components: {
    Layout,
    PageHeader,
    //Swiper,
    //SwiperSlide,
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row gx-lg-5">
              <div class="col-xl-4 col-md-8 mx-auto">
                <div id="gallery" >
                  <vue-picture-swipe :items="galleryItems"></vue-picture-swipe>
                </div>
               
              </div>
              <div class="col-xl-8">
                <div class="mt-xl-0 mt-5">
                  <div class="d-flex">
                    <div class="flex-grow-1">
                      <h4>{{ item.Descrizione }}</h4>
                      <div class="hstack gap-3 flex-wrap">
                        <div>
                          <a href="#" class="text-primary d-block"
                            >{{item.Produttore}}
                          </a>
                        </div>
                        <div class="vr"></div>
                        <div class="text-muted">
                          <a href="#" class="text-primary d-block"
                            >{{item.CodArt}}
                          </a>                        
                        </div>
                        <div class="vr"></div>
                        <div class="text-muted">
                          <a href="#" class="text-primary d-block"
                            >{{item.EAN}}
                          </a> 
                        </div>
                        <div class="vr"></div>
                        <div class="text-muted">
                          <a href="#" class="text-primary d-block"
                            >{{ repl(item.CodiceGruppoScontoProdotto,'_')}}
                          </a> 
                        </div>
                      </div>
                    </div>
                    <div class="flex-shrink-0">
                      <div>
                       
                      </div>
                    </div>
                  </div>
                  <div class="mt-4 text-muted">
                    <template v-if="item.price_list">
                      <template v-if="item.price_list[0].Prezzo!=null">
                        <p v-if="salesRouteList.length>0" v-html="salesRouteFilter(item.price_list[0].Prezzo,item.CodMarca,item.CodiceGruppoScontoProdotto) "></p> 
                      </template>
                    </template>

                  </div>
                  <div class="mt-4 text-muted">
                    <template v-if="item.price_list" >
                        <b-button title="inserisci nel prentivo" variant="info" class="" @click="addTC(item.gcode, item.CodMarca,1)">
                         <i class=" bx bx-plus"></i><i class=" bx bx-cart"></i>
                          Aggiungi al carrello
                        </b-button>
                      </template>
                  </div>
                  <div class="mt-4 text-muted">
                    <h5 class="fs-14">Descrizione :</h5>
                    <p>{{ item.Descrizione }}</p>
                  </div>

                  <div class="product-content mt-5 mb-50">
                    <h4 class="fs-14 mb-3">Allegati e schede tecniche :</h4>
                    <div class="row mb-20">
                      <div class="col-lg-2 col-md-12">
                        <strong>Thumb</strong>
                      </div>
                      <div class="col-lg-4 col-md-12">
                        <strong>Nome File</strong>
                      </div>
                      <div class="col-lg-2 col-md-12">
                        <strong>Tipo file</strong>
                      </div>
                      <div class="col-lg-2 col-md-12">
                        <strong>Dimensioni</strong>
                      </div>
                    </div>
                    <div class="row mt-50" v-for="(item, index) of fileList" :key="index">
                      <div class="col-lg-2 col-md-12">
                        
                        <template v-if="fileTypeCheck(item.mimetype)=='image'">
                          <template v-if="item.bucket!='public/data'">
                            {{ getFilePath(item.path,index) }}
                            <template v-if="fileList[index].resp">
                              <a :href="fileList[index].resp"><img :src="fileList[index].resp" alt="" class="rounded avatar-sm shadow" /></a>
                              <br><a :href="fileList[index].resp">Scarica</a>
                            </template>
                          </template>
                        </template>
                        <template v-else-if="fileTypeCheck(item.mimetype)=='application/vnd.ms-excel'">
                          <template v-if="item.bucket!='public/data'">
                          {{ getFilePath(item.path,index) }}
                          <template v-if="fileList[index].resp">
                            <a :href="fileList[index].resp"><b-img src="@/assets/images/modello-excel.png" alt="" class="rounded avatar-sm shadow"></b-img></a>
                            <br><a :href="fileList[index].resp">Scarica</a>
                          </template>
                          </template>
                        </template>
                        <template v-else-if="fileTypeCheck(item.mimetype)=='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'">
                          <template v-if="item.bucket!='public/data'">
                            {{ getFilePath(item.path,index) }}
                            <template v-if="fileList[index].resp">
                              <a :href="fileList[index].resp"><img src="@/assets/images/modello-xls.png" alt="" class="rounded avatar-sm shadow"/></a>
                              <br><a :href="fileList[index].resp">Scarica</a>
                            </template>
                          </template>
                        </template>
                        <template v-else-if="fileTypeCheck(item.mimetype)=='application/msword'">
                          <template v-if="item.bucket!='public/data'">
                            {{ getFilePath(item.path,index) }}
                            <template v-if="fileList[index].resp">
                              <a :href="fileList[index].resp"><img src="@/assets/images/modello-docx.png" alt="" class="rounded avatar-sm shadow"/> </a>
                              <br><a :href="fileList[index].resp">Scarica</a>
                            </template>
                          </template>
                        </template>                       
                        <template v-else-if="fileTypeCheck(item.mimetype)=='application/vnd.openxmlformats-officedocument.wordprocessingml.document'">
                          <template v-if="item.bucket!='public/data'">
                          {{ getFilePath(item.path,index) }}
                          <template v-if="fileList[index].resp">
                            <a :href="fileList[index].resp"><img src="@/assets/images/modello-docx.png" alt="" class="rounded avatar-sm shadow"/></a>
                            <br><a :href="fileList[index].resp">Scarica</a>
                          </template>
                          </template>
                        </template>       
                        <template v-else-if="fileTypeCheck(item.mimetype)=='application/pdf'">
                          <template v-if="item.bucket!='public/data'">
                          {{ getFilePath(item.path,index) }}
                          <template v-if="fileList[index].resp">
                            <a :href="fileList[index].resp"><img src="@/assets/images/modello-pdf.png" alt="" class="rounded avatar-sm shadow"/></a>
                            <br><a :href="fileList[index].resp">Scarica</a>
                          </template>
                          </template>
                        </template> 
                        <template v-else>
                          <template v-if="item.bucket!='public/data'">
                          {{ getFilePath(item.path,index) }}
                          <template v-if="fileList[index].resp">
                            <a :href="fileList[index].resp"><img src="@/assets/images/modello-download.png" alt="" class="rounded avatar-sm shadow"/></a>
                            <br><a :href="fileList[index].resp">Scarica</a>
                          </template>
                          </template>
                        </template> 
                      
                      </div>
                      <div class="col-lg-4 col-md-12">
                        {{ item.originalname }}
                      </div>
                      <div class="col-lg-2 col-md-12">
                        {{ item.mimetype }}
                      </div>
                      <div class="col-lg-2 col-md-12">
                        {{ formatKb(item.size) }}
                      </div>
                    </div>
                    
                  </div>
                  <!-- product-content -->

                  <!-- end card body -->
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
